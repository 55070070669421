<template>
    <el-row justify="center" class="mt-50">
        <el-col :span="24">
            <el-alert v-if="success_msg" :title="success_msg" type="success" />

            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="invoice_no" label="Invoice No." width="180" />
                <el-table-column prop="total_amount" label="Total Amount" width="180" />
                <el-table-column prop="operational_status" label="Order Status">
                    <template #default="scope">
                        <el-button type="warning" round size="small">{{ scope.row.operational_status }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="operational_status" label="Action">
                    <template #default="scope">

                        <el-popconfirm confirm-button-text="Yes" cancel-button-text="No" :icon="InfoFilled"
                            icon-color="#626AEF" title="Are you sure to cancel this?" @confirm="cancelOrder(scope.row)"
                            @cancel="cancelEvent">
                            <template #reference>
                                <el-button type="danger" v-if="scope.row.operational_status != 'cancel'" size="small"
                                    round>Cancel</el-button>
                            </template>
                        </el-popconfirm>

                        <!-- <el-button v-if="scope.row.operational_status == 'Pending'" type="danger" round size="small"
                        @click="cancelOrder(scope.row)">Cancel</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
    name: 'orderList',
    computed: {
        ...mapGetters('auth', ['authUser', 'loggedIn']),
    },
    data() {
        return {
            tableData: [],
            success_msg: null,
        };
    },
    created() {
        this.getOrderList();
    },
    methods: {
        getOrderList() {
            axios.post('/api/web/user/order/list').then((res) => {
                this.tableData = res.data.data;
            })
        },
        cancelOrder(order) {

            axios.post('/api/web/user/order/cancel', order).then((res) => {
                console.log('res', res, res.code, res.data.code);

                if (res.data.code == 200) {

                    this.success_msg = res.data.message;
                    this.getOrderList();
                }

            })
        }
    }
};
</script>

<style scoped>
.mt-50 {
    margin-top: 50px;
}
</style>
<style>
.user-menu .el-menu {
    border-right: 0px !important;
}
</style>
